import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { NzAvatarComponent, NzAvatarModule } from 'ng-zorro-antd/avatar';

@Component({
  standalone: true,
  selector: 'app-avatar',
  template: `
    <nz-avatar
      nzIcon="icons:user-1-line"
      [nzSrc]="src"
      [nzSize]="size"
    ></nz-avatar>
  `,
  imports: [NzAvatarModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input() src: NzAvatarComponent['nzSrc'];
  @Input() size: NzAvatarComponent['nzSize'] = 'default';

  @HostBinding('class')
  protected readonly hostClass = 'app-avatar';

  @Input() @HostBinding('class.centered') centered = false;
}
